@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Fugaz+One&family=Lexend+Tera:wght@100..900&family=Lexend:wght@100..900&family=Michroma&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@300;400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Teko:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: "cdl";
    src: url("../fonts/ClashDisplay-Light.otf");
}
@font-face {
    font-family: "cdr";
    src: url("../fonts/ClashDisplay-Regular.otf");
}
@font-face {
    font-family: "cdm";
    src: url("../fonts/ClashDisplay-Medium.otf");
}
@font-face {
    font-family: "cdb";
    src: url("../fonts/ClashDisplay-Bold.otf");
}

h1,h2,h3,h4,h5{
    padding: 0;
    margin: 0;
}

a{
    color: white;
    text-decoration: none;
}

/* Add these styles to your style.css or component-specific styles */

.embla {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .embla__container {
    display: flex;
    user-select: none;
  }
  
  .embla__slide {
    position: relative;
    min-width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .embla__prev,
.embla__next {
  position: absolute;
  top: 75%;
  transform: translateY(-50%);
  border: none;
  color: rgb(255, 255, 255);
  padding: 0.5vw;
  font-size: 1.5vw;
  font-family: "montserrat";
  cursor: pointer;
  z-index: 10;
  background: none;
}

.embla__prev {
  left: 1vw;
}

.embla__next {
  right: 1vw;
}

.test-name-box{
width: 100%;
height: 16vh;
background-color: rgb(57, 57, 57);
display: flex;
flex-direction: column;
align-items: center;
}
.test-top{
  width: 80%;
  height: 50vh;
  display: flex;
}
.test-img-box{
    width: 35%;
    height: 45vh;
    background-color: #fdb73e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: translateY(15vh);
}
.test-img-box img{
    width: 100%;
    position: absolute;
    bottom: 0;
}
.test-cont{
width: 65%;
height: 50vh;
position: relative;
display: flex;
align-items: flex-end;
justify-content: center;
}

.test-cont img{
  width: 8%;
  position: absolute;
  left: 3vw;
  top: 20vh;
  opacity: 1;
}
.test-name{
  color: #fdb73e;
  text-transform: uppercase;
  font-weight: 500;
  width: 10vw;
  margin-left: 5vw;
  margin-top: 1vw;
}

.test-job{
  color: #ffffff;
  width: 10vw;
  margin-left: 5vw;
}
.test-cont p{
  position: relative;
 padding-left: 7vw;
  color: rgb(255, 255, 255);
  margin-bottom: 5vh;
  font-size: 0.9vw;
  line-height: 1.5vw;
}


@media screen and (max-width:600px) {
  html,body{
    overflow-x: hidden;
  }
}