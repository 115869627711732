body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* gallery */
.gallery-section{
  width: 100%;
  height: auto;
  padding: 2vw 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  position: relative;
}
.gallery-glow{
 width: 30vw;
 height: 30vw;
 position: absolute;
 border-radius: 50%;
 right: -20vw;
 top: -15vw;
 z-index: 5;
 filter: blur(80px);

}
.galplus{
 width: 100%;
 height: 100%;
 background-color: #0000006c;
 position: absolute;
 z-index: 2;
 display: flex;
 align-items: center;
 justify-content: center;
 opacity: 0;
 transition: all 0.5s ease;
}
.galplus i{
 color: white;
 font-size: 1.2vw;
 border: 1px solid #ed1459;
 width: 3vw;
 height: 3vw;
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #ed1459;
 border-radius: 50%;
}
.gallery-section .title{
 width: 80%;
 color: #fdb73e;
 font-family: 'cdm';
}
.title span{
  color: #fff;
  font-family: 'cdl';
}
.gallery {
 display: flex;
 align-items: center;
 justify-content: center;
 flex-wrap: wrap;
 gap: 1vw;
 width: 80%;
 height:90vw;
 position: relative;
 z-index: 6;
}

.box {
 width: 20vw;
 height: 20vw;
 perspective: 100vw;
 position: relative;
 cursor: pointer;
 position: absolute;
 border-radius: 1vw;
 overflow: hidden;
}
.box:hover .galplus{
 opacity: 1;
}
.box:hover .front{
 background-position: 5%;
}

#box1{
 width: 31vw;
 height: 31vw;
 top : 0vw;
 left: 0;
}
#box2{
 width: 15vw;
 height: 15vw;
 top : 0vw;
 left: 32vw;
}
#box3{
 width: 15vw;
 height: 15vw;
 top : 0vw;
 left: 48vw;
}
#box4{
 width: 15vw;
 height: 15vw;
 top : 0vw;
 left: 64vw;
}
#box5{
 width: 15vw;
 height: 15vw;
 top : 16vw;
 left: 32vw;
}
#box6{
 width: 15vw;
 height: 15vw;
 top : 16vw;
 left: 48vw;
}
#box7{
 width: 15vw;
 height: 15vw;
 top : 16vw;
 left: 64vw;
}
#box8{
 width: 23.5vw;
 height: 23.5vw;
 top : 32vw;
 right: 0vw;
}
#box9{
 width: 22.5vw;
 height: 23.5vw;
 top : 32vw;
 right: 24.5vw;
}
#box10{
 width: 15vw;
 height: 15vw;
 top : 32vw;
 left: 0;
}

#box11{
 width: 15vw;
 height: 15vw;
 top : 32vw;
 left: 16vw;
}
#box12{
 width: 15vw;
 height: 15vw;
 top : 48vw;
 left: 16vw;
}
#box13{
 width: 15vw;
 height: 15vw;
 top : 48vw;
 left: 0vw;
}
#box14{
 width: 31vw;
 height: 31vw;
 top : 56.5vw;
 right: 0.1vw;
}
#box15{
 width: 15vw;
 height: 14vw;
 top : 56.5vw;
 right: 32.1vw;
}
#box16{
 width: 31vw;
 height: 24vw;
 top : 64vw;
 left: 0;
}
#box17{
 width: 15vw;
 height: 16.2vw;
 top : 71.5vw;
 left: 32vw;
}



.box div {
 width: 100%;
 height: 100%;
 position: absolute;
 transition: transform 0.6s, opacity 0.6s;
 transform-style: preserve-3d;
 backface-visibility: hidden;
 
}

.box .front {
 background-size: 105%;
 background-position: center;
 opacity: 1;
 transition: all 0.5s ease;
}

.box .back {
background: linear-gradient(#ed1459,#940f39);
 transform: rotateY(180deg);
}

.box.flipped .front {
 transform: rotateY(180deg);
}
.box.flipped .galplus{
 display: none;
}

.box.flipped .back {
 transform: rotateY(0deg);
}

.box .fade {
 transition: opacity 0.6s;
 opacity: 0;
}

/* Popup styles */
.popup {
 display: none; /* Hidden by default */
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.8);
 align-items: center;
 justify-content: center;
 z-index: 1000;
}

.popup-img {
 max-width: 90%;
 max-height: 90%;
}

.popup-close {
 position: absolute;
 top: 20px;
 right: 20px;
 font-size: 2rem;
 color: white;
 cursor: pointer;
}
.box img{
  width: 100%;
}
.title{
  margin-top: 3vw;
  font-size: 4vw;
  font-weight: 700;
  font-family: 'cdl';
  color: #fff;
}

@media screen and (max-width:600px) {

  .title {
    margin-top: 23vw;
    font-size: 8vw;
  }
  .gallery{
    height: auto;
  }
  .box{
    position: static;
  }

  #box1,#box2,#box3,#box4,#box5,#box6,#box7,#box8,#box9,#box10,#box11,#box12,#box13,#box14,#box15,#box16,#box17{
    width: 26vw;
    height: 26vw;
  }
}